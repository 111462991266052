'use client'
import { useImmutableChannel } from '@/api/hooks'
import FileInput from '@/app/components/file-input'
import Button, { SubmitButton } from '@/components/button'
import { FlashMessageContext } from '@/context/flash-message'
import UserContext from '@/context/user-context'
import { cn } from '@/util/cn'
import { giphyLocalUrl } from '@/app/util/env'
import { getCookie, getPost } from '@/util/url'
import { useContext, useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'
import { timeoutFetch } from 'utils/src/api/fetch'
import { TrashIcon } from 'ui/src/icons/creation'
import { ModalContext } from '../../modal'
import { EditCollectionForm } from '../action-buttons'
import PrivateToggle from '../private-toggle'
import { CollectionGif, CollectionLabel, Divider, cardClassName, inputClassName } from '../ui'

const EditPanel = () => {
    const { user } = useContext(UserContext)
    const { mutate } = useSWRConfig()
    const { showStatusMessage } = useContext(FlashMessageContext)
    const { goBack, modal } = useContext(ModalContext)
    const { data: channel } = useImmutableChannel(modal?.channel)
    const [displayInput, setDisplayInput] = useState('')
    useEffect(() => {
        if (channel) {
            setDisplayInput(channel?.display_name)
        }
    }, [channel])
    return channel ? (
        <EditCollectionForm className="flex flex-col items-center gap-2 p-2 font-bold " channelId={channel.id}>
            <div className={cn(cardClassName, 'bg-giphyIndigo')}>
                <CollectionGif gif={channel.featured_gif} />
                <CollectionLabel label={displayInput} />
            </div>
            <label className="text-giphyWhite self-start" htmlFor="channel">
                Name
            </label>
            <input
                type="text"
                spellCheck={false}
                value={displayInput}
                placeholder={'Collection Name'}
                name="display_name"
                autoComplete="off"
                onChange={(e) => setDisplayInput(e.target.value)}
                autoCorrect="off"
                className={cn(inputClassName, 'peer/input w-full')}
            />
            <div className="text-giphyWhite flex w-full justify-between">
                <label>Description</label>
                <label className="text-giphyLightCharcoal self-baseline text-sm">Optional</label>
            </div>
            <textarea
                defaultValue={channel.description}
                maxLength={200}
                placeholder={'Add a brief description about your collection.'}
                name="description"
                className={cn(inputClassName, 'peer/textarea h-20 w-full')}
            />
            <div className="text-giphyWhite flex w-full justify-between">
                <label>
                    Banner <span className="text-giphyDarkCharcoal text-xs italic">5 mb max, 1040x160</span>
                </label>
                <label className="text-giphyLightCharcoal self-baseline text-sm">Optional</label>
            </div>
            <FileInput
                className="bg-giphyBlack text-giphyWhite w-full px-4 py-2"
                placeholder="Choose File"
                accept={['image/*']}
                name="banner"
            ></FileInput>
            <label className="text-giphyWhite self-start" htmlFor="private">
                Privacy{' '}
            </label>
            <div className="flex w-full items-end justify-between">
                <PrivateToggle initialIsPrivate={channel.is_private} />
                <Button
                    className="bg-giphyBlack text-giphyWhite flex h-9 cursor-pointer items-center"
                    onClick={async (e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (window.confirm('Are you sure you want to delete this collection?')) {
                            const result = await timeoutFetch({
                                url: `${giphyLocalUrl}/api/v4/channels/${channel.id}`,
                                options: {
                                    ...getPost(getCookie('csrftoken')),
                                    method: 'DELETE',
                                },
                            })
                            showStatusMessage('Collection Deleted', 'Failed to delete collection', result.ok)
                            if (result.ok) {
                                mutate(`${giphyLocalUrl}/api/v4/channels/${channel.parent}`)
                                goBack()
                            }
                        }
                    }}
                >
                    {/* @ts-ignore use css instead of color prop */}
                    <TrashIcon height={16} color={null} className="fill-deleteColor mr-2" /> Delete Collection
                </Button>
            </div>
            <input type="hidden" name="user_id" value={user?.id} />
            <input type="hidden" name="id" value={channel?.id} />
            <input type="hidden" name="parent" value={channel?.parent} />
            <Divider />
            <SubmitButton
                className="
                w-full cursor-pointer select-none
                peer-placeholder-shown/input:pointer-events-none
                peer-placeholder-shown/input:opacity-50"
            >
                Save Changes
            </SubmitButton>
        </EditCollectionForm>
    ) : null
}
export default EditPanel
