import { useGiphyFetch } from '@/app/util/use-gf'
import { Result, SearchOptions } from '@giphy/js-fetch-api'
import { IChannel, IGif } from '@giphy/js-types'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import { GenericResult } from 'ui/src/hooks/use-fetch-data'
import { giphyLocalUrl, publicRuntimeConfig } from '@/app/util/env'

const { apiUrl, mobileApiKey } = publicRuntimeConfig
const apiUrlV2 = apiUrl.replace('v1', 'v2')

type TrendingSearch = { name: string; analytics_response_payload: string }

const getParams = (obj: any) => new URLSearchParams(obj).toString()

export function useGif(id?: string) {
    const gf = useGiphyFetch()
    return useSWR<IGif>(id ? id : null, (id: string) => gf.gif(id).then((res) => res.data))
}

export function useImmutableChannel(channelId: number | string | null = null) {
    // we will load an immutable channel and apply mutations through the SWR mutate api
    return useSWRImmutable<IChannel>(channelId ? `${giphyLocalUrl}/api/v4/channels/${channelId}?fetch_children=true` : null)
}

export const useTrendingSearches = (term: string, options: SearchOptions = {}) =>
    useSWR<GenericResult<TrendingSearch>>(
        `${apiUrlV2}trending/searches?${getParams({
            ...options,
            q: encodeURIComponent(term),
            api_key: mobileApiKey,
        })}`
    )

export const useSearchChannel = (channelName: string, options: SearchOptions = {}) =>
    useSWR<GenericResult<IChannel>>(
        channelName
            ? `${apiUrl}channels/search?${getParams({
                  ...options,
                  q: encodeURIComponent(channelName.replace('@', '')),
                  api_key: mobileApiKey,
              })}`
            : null
    )

export const useSearchChannelByTerm = (term: string, options: SearchOptions = {}) =>
    useSWR<GenericResult<IChannel>>(
        `${apiUrl}channels/search?${getParams({
            ...options,
            q: term,
            api_key: mobileApiKey,
        })}`
    )

type SearchName = { analytics_response_payload: string; name: string; response_id: string }
type SearchTagsResult = { data: SearchName[] } & Result
export const useSearchTags = (term: string, options: SearchOptions = {}) =>
    useSWR<SearchTagsResult>(
        `${apiUrl}gifs/search/tags?${getParams({
            ...options,
            q: term,
            api_key: mobileApiKey,
        })}`
    )
