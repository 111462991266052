import { IGif } from '@giphy/js-types'
import { SyntheticEvent } from 'react'

export const relativeUrl = (url: string) => `/${url.replace(/^(?:\/\/|[^\/]+)*\//, '')}`

export const relativeGifClick = (gif: IGif, e: SyntheticEvent<HTMLElement, Event>) => {
    e.preventDefault()
    e.stopPropagation()
    location.href = relativeUrl(gif.url)
}

export function getCookie(cookieName: string, cookies?: string) {
    const pairs = (cookies || document.cookie).split(';')
    for (let i = 0; i < pairs.length; i++) {
        let cookie = pairs[i].trim()
        if (cookie.startsWith(cookieName + '=')) {
            return cookie.substring(cookieName.length + 1)
        }
    }
    return ''
}

export const appendParams = (url: URL, qs: { [key: string]: any }) => {
    Object.entries(qs).forEach(([key, value]) => {
        url.searchParams.append(key, value)
    })
    return url
}

export const giphyDecode = (str: string) => {
    return str.replace(/-/g, ' ')
}

export const giphyEncode = (str: string) => {
    return str.replace(/ /g, '-')
}

export function getIdFromSlug(id: string): string {
    return id.split('-').pop()!
}

export function getPost(csrftoken: string, isFormData = false) {
    return {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
            'X-CSRFToken': csrftoken,
            'X-Requested-With': 'XMLHttpRequest',
            credentials: 'same-origin',
        },
    }
}

interface EndpointResult<T> {
    results: T[]
    next: string | null
}

export const convertToPagination = <T>(result: EndpointResult<T>, offset: number) => {
    const { results: data = [] } = result
    const count = data.length
    return {
        data,
        pagination: {
            count,
            total_count: Infinity,
            offset,
        },
        meta: {
            msg: '',
            response_id: '',
            status: 200,
        },
    }
}
